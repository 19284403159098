import * as React from "react";
import "../index.scss";
import { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";
import { navigate } from "gatsby-link";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Loader from "../components/loader/loader";

const SuccessPage = () => {
  const [orderId, setOrderId] = useQueryParam("ordernumber", StringParam);

  const [isOrderChecking, setIsOrderChecking] = useState(true);

  useEffect(() => {
    if (!orderId) {
      navigate("/");
    }

    checkOrderInterval();
  }, []);

  const interval = useRef(null);

  const checkOrderInterval = () => {
    interval.current = setInterval(() => checkOrder(), 2000);
  };

  const checkOrder = async () => {
    try {
      const response = await fetch(
        `${process.env.API_URL}/api/order/${orderId}`,
        {
          method: "GET",
          headers: { "user-id": localStorage.getItem("user-id") || null },
        }
      );
      const data = await response.json();
      const { paid } = data.order;

      if (paid) {
        clearInterval(interval.current);
        setIsOrderChecking(false);
      }
    } catch (e) {
      clearInterval(interval.current);
      console.log(e);
      navigate("/");
    }
  };

  useEffect(() => () => clearInterval(interval.current), [interval.current]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Заказ успешно оплачен</title>
        <meta
          name="description"
          content="Проверить автомобиль по VIN коду на наличие залогов, ограничений и запретов, а также узнать историю размещений на Куфаре"
        />
        <meta
          name="keywords"
          content="реестр залогов, VIN, проверка на угон, проверка на залог, машины из России, VIN-декодер"
        />
      </Helmet>

      <Header />
      <div className="container success-page">
        <Link to={"/"} className="link home-link">
          Главная
        </Link>
        {isOrderChecking ? (
          <div className="loader-wrapper">
            <Loader />
          </div>
        ) : (
          <>
            <h2 className="title">Ваш заказ № {orderId} успешно оплачен</h2>
            <h3 className="success-subtitle">
              Мы получаем информацию от источников для формирования отчёта, этот
              процесс занимает порядка 10 минут. <br />
              Сформированный отчёт придет на Ваш e-mail в формате pdf. <br />
              В случае возникновения вопросов по отчёту, просим отправлять
              запросы по электронной почте support@kufar.by. <br />
              Спасибо, что воспользовались сервисом проверки автомобиля по VIN
              от площадки объявлений Куфар!
            </h3>
            <Link to={"/"} className="check-more-link">
              <button className="btn-blue ">Проверить еще автомобиль</button>
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default SuccessPage;
